import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/components/index.css";
import "./pages/index.css";
import "../src/index.css";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import theme from "./theme";
import { ThemeProvider } from "@mui/material";
import AppRoutes from "./routes";
import LoaderContext from "./context/LoaderContext";
import AwsAmplifyInit from "./http/services/aws-amplify";
import { AuthContext, useAuth } from "./context/AuthContext";
import { SnackbarProvider } from "notistack";
import { AxiosInterceptorContextProvider } from "./context/AxiosInterceptor";
import { getLocalStorageValue } from "./http/services/local-storage-service";
import NotificationContext from "./context/NotificationContext";
import { environment } from "./environment/environment";

const App = () => {
  AwsAmplifyInit();
  const [isLoader, setIsLoader] = useState(false);
  const [authUser, setAuthUser] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const { startAutoLogoutTimer, sessionAutoLogoutTimerClear } = useAuth();
  useEffect(() => {
    if (localStorage?.getItem("data")) {
      setAuthUser(
        getLocalStorageValue("authUser")
          ? JSON.parse(getLocalStorageValue("authUser"))
          : null
      );
    } else {
      localStorage.clear();
    }
  }, []);
  useEffect(() => {
    if (localStorage?.getItem("data")) {
      setAuthUser(
        getLocalStorageValue("authUser")
          ? {
              ...JSON.parse(getLocalStorageValue("authUser")),
              profileDetails: getLocalStorageValue("agentProfileData")
                ? JSON.parse(getLocalStorageValue("agentProfileData"))
                : {},
            }
          : null
      );
    } else {
      localStorage.clear();
    }
  }, []);
  useEffect(() => {
    startAutoLogoutTimer();
    return () => sessionAutoLogoutTimerClear();
  }, []);
  useEffect(() => {
    if (environment?.inspect_element_disable === "true") {
      const handleContextMenu = (e) => {
        e.preventDefault();
      };
      document.addEventListener("contextmenu", handleContextMenu);
      const handleKeyDown = (e) => {
        if (
          (e.ctrlKey && e.shiftKey && e.key === "I") ||
          (e.ctrlKey && e.shiftKey && e.key === "J") ||
          (e.ctrlKey && e.key === "U") ||
          e.key === "F12"
        ) {
          e.preventDefault();
        }
      };
      document.addEventListener("keydown", handleKeyDown);

      return () => {
        document.removeEventListener("contextmenu", handleContextMenu);
        document.removeEventListener("keydown", handleKeyDown);
      };
    }
  }, []);
  useEffect(() => {
    if (environment?.inspect_element_disable === "true") {
      const detectDevTools = () => {
        const threshold = 160;
        if (
          window.outerWidth - window.innerWidth > threshold ||
          window.outerHeight - window.innerHeight > threshold
        ) {
          window.location.href = "https://www.google.com/";
        }
      };

      window.addEventListener("resize", detectDevTools);
      detectDevTools();

      return () => {
        window.removeEventListener("resize", detectDevTools);
      };
    }
  }, []);
  return (
    <BrowserRouter basename="/">
      <AxiosInterceptorContextProvider>
        <NotificationContext.Provider
          value={{ notificationCount, setNotificationCount }}
        >
          <AuthContext.Provider value={{ authUser, setAuthUser }}>
            <LoaderContext.Provider value={{ isLoader, setIsLoader }}>
              <SnackbarProvider
                maxSnack={3}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              >
                <Provider store={store}>
                  <ThemeProvider theme={theme}>
                    <AppRoutes />
                  </ThemeProvider>
                </Provider>
              </SnackbarProvider>
            </LoaderContext.Provider>
          </AuthContext.Provider>
        </NotificationContext.Provider>
      </AxiosInterceptorContextProvider>
    </BrowserRouter>
  );
};

export default App;
